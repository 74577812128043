<template>
    <div>
        <validation-provider
            mode="aggressive"
            v-slot="{ errors }"
            :vid="name"
            :rules="validation"
        >
            <div class="input">
                <label
                    v-if="label"
                    :for="name"
                >{{ label }}</label>
                <cleave
                    ref="formFieldInput"
                    type="text"
                    inputmode="decimal"
                    class="form-control"
                    :raw="true"
                    :id="name"
                    :options="options"
                    :class="[errors[0] ? 'is-invalid' : null, formClasses, appendBottomText ? 'rounded-bottom-0' : null]"
                    :name="name"
                    :placeholder="placeholder"
                    :value="value"
                    v-model="cleaveValue"
                />
                <div
                    v-if="appendBottomText"
                    class="append-bottom-text bg-light small p-1 ps-2 rounded-bottom border-bottom border-left border-right"
                >
                    {{ appendBottomText }}
                </div>
                <div class="invalid-feedback">
                    {{ errors[0] }}
                </div>
            </div>
        </validation-provider>
    </div>
</template>

<script>
    /**
     * Use for all text input fields. Has label, validation, placeholder and filters.

     * @prop{String} name (required) To modify the text formatting. Can be 'uppercase|lowercase'.

     * @prop{String} validation (optional) A string with the vee-validate rules string. ie 'required|max:7'
     * @prop{String} label (optional) A label above the input.
     * @prop{String} classes (optional) A string of any classes you want to apply to the input field
     * @prop{String} placeholder (optional) The text when the input is blank.
     * @prop{String} filter (optional) To modify the text formatting. Can be 'uppercase|lowercase'.
     * @prop{Boolean} focus (optional) To set focus on the input. Defaults to false.
     */
    import { ValidationProvider } from 'vee-validate'
    import Cleave from 'vue-cleave-component'
    import 'cleave.js/dist/addons/cleave-phone.us'
    import { formInputMixin, formInputCleaveMixin } from '@/mixins/formInputMixin'

    export default {
        name: 'FormCurrencyField',
        mixins: [formInputMixin, formInputCleaveMixin],
        props: {
            options: {
                type: Object,
                default: function () {
                    return {
                        numeral: true,
                        prefix: '$',
                        noImmediatePrefix: true,
                        rawValueTrimPrefix: true,
                        numeralPositiveOnly: true,
                    }
                },
            },
            placeholder: {
                type: String,
                default: '$0.00',
            },
            validation: { type: String, default: 'required' },
            appendBottomText: { type: String, default: '' },
        },
        components: {
            'validation-provider': ValidationProvider,
            cleave: Cleave,
        },
        created() {
            this.cleaveValue = this.value
        },
        data: () => ({
            cleaveValue: '',
        }),
        watch: {
            cleaveValue(value) {
                this.$emit('input', value)
            },
        },
    }
</script>
