import throttle from 'lodash/throttle'
import { appSessionStorage, localStorageKey } from '@/utils/storage'
import { logger } from '@/utils/logger'

export const misc = {
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        parseAndSaveUtmTags: async function (to, from) {
            const fromPath = from.path
            if (fromPath === '/' && !appSessionStorage.getItem(localStorageKey.sessionId)) {
                appSessionStorage.setItem(localStorageKey.utmTags, JSON.stringify(to.query))
            }
        },
        throttleLogging: throttle((inputValue, logPrefix) => {
            if (inputValue) {
                logger.info(`${logPrefix}: ${inputValue}`)
            }
        }, 1000),
        scrollToTop() {
            document.body.scrollTop = 0 // For Safari
            document.documentElement.scrollTop = 0
        },
    },
}
