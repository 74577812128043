<template>
    <onboarding-layout
        :error-text="errorText"
        :loading="loading"
        :title="title"
        :loading-title="$t('global.loadingMessage.loading')"
    >
        <application-form
            ref="basicInfoForm"
            @on-submit="onSubmit"
            :button-cta="buttonCta"
            :show-first-last-name-input="false"
            :show-s-s-n-input="!isCoApplicant"
            :show-address-input="false"
            :must-be-c-a-resident="false"
            :show-email-input="true"
            :show-stated-income-input="false"
            :show-identity-check-section-header="false"
            :show-soft-pull-legal="false"
            :show-no-soft-pull-legal="true"
            :last-four-only="false"
        />
    </onboarding-layout>
</template>

<script>
    import OnboardingLayout from '@/layouts/Onboarding'
    import { Flows, getNextRoute } from '@/flow/flowController'
    import { appSessionStorage, localStorageKey } from '@/utils/storage'
    import { getApplicantReturning, postUpdateApplicantFields, getNextSigningAction, NextSigningAction, postUpdateApplicantAddress } from '@/services/api'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import { i18n } from '@/utils/i18n'
    import ApplicationForm from '@/components/ApplicationForm'
    import assert from 'assert'
    import { logger } from '@/utils/logger'
    import { sharedPagePaths } from '@/routes/sharedRoutes'
    import { startCase, toLower } from 'lodash'
    import { originationPagePaths } from '@/routes/originationRoutes'
    import router from '@/routes/router'
    import { MiscThanksReasons } from '@/utils/thanksPageHelpers'

    export default {
        components: {
            'onboarding-layout': OnboardingLayout,
            'application-form': ApplicationForm,
        },
        data() {
            return {
                errorText: '',
                loading: true,
                returnToken: this.$route.query?.p,
                buttonCta: i18n.t('global.cta.continue'),
                isCoApplicant: false,
                isCoOwner: false,
                firstName: '',
            }
        },
        computed: {
            title() {
                return `Hi ${startCase(toLower(this.firstName))} 👋  Please verify the following information.`
            },
        },
        created: function () {
            appSessionStorage.setItem(localStorageKey.currentFlow, Flows.origination)
        },
        mounted: async function () {
            try {
                if (appSessionStorage.getItem(localStorageKey.jwtTokens)) {
                    logger.info(`User hit secondary user personal info page with jwt tokens. Clearing their stale state and reloading the page to acquire a new session`)
                    appSessionStorage.clear()
                    window.location.reload()
                    return
                }

                this.$logEvent('event_co_applicant_returned_to_notarization')

                if (!this.returnToken) {
                    logger.log('Missing return token, sending to thanks')
                    return router.push({ path: sharedPagePaths.THANKS, query: { reason: MiscThanksReasons.authorization } })
                }

                const response = await getApplicantReturning(this.returnToken)

                if (!response.data.success) {
                    logger.log('Malformed or invalid return token, sending to thanks')
                    return router.push({ path: sharedPagePaths.THANKS, query: { reason: MiscThanksReasons.authorization } })
                }

                const nextSigningActionResponse = await getNextSigningAction()
                const nextSigningAction = nextSigningActionResponse.data.payload.nextSigningAction
                logger.info(`Received next signing action ${nextSigningAction}`)
                if (nextSigningAction === NextSigningAction.allApplicantsAlreadySigned) {
                    logger.log(`All applicants have already signed & notarized! Redirecting to welcome page.`)
                    return await this.$router.replace({ path: originationPagePaths.WELCOME })
                } else if (nextSigningAction === NextSigningAction.nextApplicantShouldSign) {
                    logger.log(`Applicant with returnToken ${this.returnToken} already signed & notarized! Redirecting to next applicant's link.`)
                    return await this.$router.replace(nextSigningActionResponse.data.payload.redirectTo)
                }

                this.firstName = response.data.payload.firstName
                this.isCoApplicant = response.data.payload.isCoApplicant
                this.isCoOwner = response.data.payload.isCoOwner
                this.$logEvent('view_co_owner_return', { returnToken: this.returnToken })
                this.loading = false
            } catch (e) {
                this.errorText = ApiErrorHandler(e)
            }
        },
        methods: {
            onSubmit: async function (data) {
                if (this.loading) {
                    console.log('Still loading, refusing to submit')
                    return
                }
                this.basicInfo = data
                await this.submitInfo(this.basicInfo)
            },
            updateApplicantAddress: async function () {
                const addressData = this.$refs.basicInfoForm.addressData
                if (addressData && !this.isCoApplicant && !this.isCoOwner) {
                    const payload = {
                        addressStreet: addressData.addressComponents?.addressStreet,
                        addressApt: addressData.addressComponents?.addressUnit?.trim() || undefined,
                        addressCity: addressData.addressComponents?.addressCity,
                        addressState: addressData.addressComponents?.addressState,
                        addressPostalCode: addressData.addressComponents?.addressPostalCode,
                    }
                    await postUpdateApplicantAddress(payload)
                }
            },
            submitInfo: async function (applicantInfo) {
                if (!applicantInfo) {
                    return
                }

                this.loading = true
                let isSuccessful = await this.submitBasicInfo(applicantInfo)
                if (isSuccessful) {
                    await this.$router.push(getNextRoute(this.$router))
                }
                this.loading = false
            },
            submitBasicInfo: async function (applicantInfo) {
                // TODO: Check if refactor is needed
                try {
                    this.errorText = ''
                    logger.info(`applicantInfo: ${JSON.stringify(applicantInfo)}`)
                    const postBody = this.basicInfoPostBody(applicantInfo)
                    logger.info(`postBody: ${JSON.stringify(postBody)}`)
                    const response = await postUpdateApplicantFields(postBody)
                    logger.info(`basicInfo response: ${JSON.stringify(response.data)}`)
                    this.successfullySubmittedBasicInfo = response.data.success

                    if (!this.successfullySubmittedBasicInfo) {
                        let errorMessage = ''
                        const errors = response.data.payload.errors
                        logger.info(`errors: ${JSON.stringify(errors)}`)
                        const updatedBasicInfo = JSON.parse(appSessionStorage.getItem(localStorageKey.basicInfo))
                        errors.forEach((error) => {
                            errorMessage += errorMessage.length > 0 ? '<br/>' : ''
                            if (error.errorCode === 'INVALID_US_PHONE_NUMBER_ERROR') {
                                errorMessage += i18n.t('customValidationRules.notValidPhoneNumber')
                            } else if (error.errorCode === 'INVALID_ADDRESS_ERROR') {
                                errorMessage += i18n.t('components.formFieldAddress.validation.addressNotFound')
                            } else if (error.errorCode === 'AMBIGUOUS_ADDRESS_ERROR') {
                                errorMessage += i18n.t('global.errors.confirmAddress')
                                updatedBasicInfo.addressData.addressComponents.addressStreet = error.addressData.addressStreet
                                updatedBasicInfo.addressData.addressComponents.addressCity = error.addressData.addressCity
                                updatedBasicInfo.addressData.addressComponents.addressState = error.addressData.addressState
                                updatedBasicInfo.addressData.addressComponents.addressPostalCode = error.addressData.addressPostalCode
                            } else if (error.errorCode === 'GOOGLE_API_ERROR') {
                                errorMessage += i18n.t('global.errors.generic')
                            } else if (error.errorCode === 'applicant_under_age') {
                                errorMessage += i18n.t('customValidationRules.underAge')
                            } else if (error.errorCode === 'PHONE_NUMBER_ALREADY_EXISTS') {
                                errorMessage += i18n.t('customValidationRules.phoneNumberAlreadyExists')
                            } else if (error.errorCode === 'EMAIL_ALREADY_EXISTS') {
                                errorMessage += i18n.t('customValidationRules.emailAlreadyExists')
                            } else if (error.errorCode === 'SSN_MATCHES_PRIMARY_APPLICANT') {
                                errorMessage += i18n.t('customValidationRules.duplicateSsnError')
                            } else {
                                errorMessage += i18n.t('global.errors.badRequest')
                            }
                        })
                        assert(this.$refs.basicInfoForm, 'this.$refs.basicInfoForm must exist')
                        this.$refs.basicInfoForm.loadData(updatedBasicInfo)
                        this.errorText = errorMessage
                    }

                    await this.updateApplicantAddress()
                } catch (error) {
                    this.successfullySubmittedBasicInfo = false
                    this.errorText = ApiErrorHandler(error)
                }
                logger.info(`returning successfullySubmittedBasicInfo: ${this.successfullySubmittedBasicInfo}`)
                return this.successfullySubmittedBasicInfo
            },
            basicInfoPostBody(applicantInfo) {
                const postBody = {}

                if (applicantInfo.firstName && applicantInfo.lastName) {
                    postBody.firstName = applicantInfo.firstName
                    postBody.lastName = applicantInfo.lastName
                }

                if (applicantInfo.emailAddress) {
                    postBody.email = applicantInfo.emailAddress
                }

                if (applicantInfo.ssn) {
                    postBody.ssn = applicantInfo.ssn
                }

                if (applicantInfo.applicantDOB) {
                    postBody.dateOfBirth = applicantInfo.applicantDOB
                }
                return postBody
            },
        },
    }
</script>
