<template>
    <form-container
        id="applicationForm"
        ref="form"
        @on-submit="onSubmit"
        @onError="onError"
    >
        <div
            v-if="error"
            class="alert alert-warning"
            role="alert"
        >
            {{ errorText }}
        </div>

        <section-header v-if="showFirstLastNameInput">
            {{ $t('components.applicationForm.sectionHeader.personal') }}
        </section-header>

        <div v-if="showFirstLastNameInput">
            <div class="mb-2 col">
                <form-field
                    v-model="firstName"
                    name="firstName"
                    class="form-field"
                    :placeholder="$t('components.applicationForm.placeholder.firstName')"
                    validation="required|min:2"
                    autocomplete="given-name"
                    :disabled="disableFirstLastNameInput"
                />
            </div>
            <div class="mb-2 col">
                <form-field
                    v-model="lastName"
                    name="lastName"
                    class="form-field"
                    :placeholder="$t('components.applicationForm.placeholder.lastName')"
                    validation="required|min:2"
                    autocomplete="family-name"
                    :disabled="disableFirstLastNameInput"
                />
            </div>
        </div>

        <div
            v-if="requireConfirmPrimaryResidence"
            class="form-row mb-2"
        >
            <div class="custom-control custom-checkbox mb-2">
                <input
                    type="checkbox"
                    id="application-form-checkbox"
                    class="custom-control-input"
                    :class="primaryResidenceError ? 'is-invalid' : null"
                    v-model="isPrimaryResidenceConfirmed"
                    @input="primaryResidenceError = ''"
                >
                <label
                    class="custom-control-label"
                    for="application-form-checkbox"
                > {{ $t('components.applicationForm.confirmOwnership') }}</label>
                <div class="invalid-feedback">
                    {{ primaryResidenceError }}
                </div>
            </div>
        </div>

        <div
            class="form-row mb-2"
            v-if="showStatedIncomeInput"
        >
            <div class="col">
                <p class="section-header text-muted text-start">
                    {{ $t('components.applicationForm.sectionHeader.income') }}
                </p>
            </div>

            <form-field-currency
                v-model="statedIncome"
                name="statedIncome"
                :placeholder="$t('components.applicationForm.placeholder.statedIncome')"
            />
            <p
                class="text-muted text-center mt-1 small"
                v-html="$t('components.applicationForm.legal.statedIncome')"
            />
        </div>

        <section-header v-if="showEmailInput || showSSNInput || showDOBInput">
            {{ $t('components.applicationForm.sectionHeader.identity') }}
        </section-header>

        <div v-if="showEmailInput">
            <div class="mb-2 col">
                <form-field
                    v-model="emailAddress"
                    name="email"
                    :placeholder="$t('components.applicationForm.placeholder.email')"
                    validation="required|email"
                    input-type="email"
                    inputmode="email"
                />
            </div>
        </div>

        <div v-if="showSSNInput">
            <div class="mb-2 col">
                <form-field-ssn
                    v-model="ssn"
                    name="ssn"
                    class="form-field"
                    :last-four-only="lastFourOnly"
                />
            </div>
        </div>

        <div v-if="showDOBInput">
            <div class="mb-2 col">
                <form-field-date
                    v-model="applicantDOB"
                    name="dob"
                    validation-rules="required|dateRule|ageRule"
                    :placeholder="$t('components.applicationForm.placeholder.dateOfBirth')"
                    :focus-placeholder="$t('components.applicationForm.placeholder.dob')"
                />
            </div>
        </div>

        <section-header
            class="mt-4"
            v-if="showAddressInput"
        >
            Address
        </section-header>

        <form-field-address
            class="mb-3"
            ref="addressField"
            v-if="showAddressInput"
            @on-change="onAddressInputChange"
            @on-clear="onClearAddress"
            initial-input-type="auto"
            :must-be-c-a-resident="mustBeCAResident"
        />
        <div class="d-grid">
            <form-button
                class="mb-2"
                :image-src="showButtonIcon ? 'global/web_16_lock.svg' : undefined"
                :label="buttonCta"
                :submitting="submitting"
                type="submit"
                event-name="click_button_submit_personal_info_form"
            />
        </div>
        <div
            class="text-start"
            v-if="showSoftPullLegal"
        >
            <p
                class="fw-bold mb-1"
                v-html="$t('components.applicationForm.legal.doesNotEffectFicoHtml')"
            />
            <p
                class="text-muted small"
                v-html="$t('components.applicationForm.legal.softPullHtml')"
            />
        </div>
        <div v-if="showNoSoftPullLegal">
            <p
                class="fw-bold mb-2"
                v-html="$t('components.applicationForm.legal.doesNotEffectFicoHtml')"
            />
            <p
                class="text-muted small"
                v-html="$t('pages.origination.ssnVerification.legalText')"
            />
        </div>
    </form-container>
</template>
<script>
    import FormContainer from '@/components/base/FormContainer'
    import FormButton from '@/components/base/FormButton'
    import FormField from '@/components/base/FormField'
    import FormFieldDate from '@/components/base/FormFieldDate'
    import FormFieldAddress from '@/components/base/FormFieldAddress'
    import FormFieldCurrency from '@/components/base/FormFieldCurrency'
    import { appSessionStorage, localStorageKey } from '@/utils/storage'
    import { i18n } from '@/utils/i18n'
    import FormFieldSsn from '@/components/base/FormFieldSsn'
    import { logger } from '@/utils/logger'
    import { misc } from '@/mixins/misc'
    import SectionHeader from '@/components/SectionHeader'

    export default {
        name: 'ApplicationForm',
        components: {
            SectionHeader,
            'form-container': FormContainer,
            'form-button': FormButton,
            'form-field': FormField,
            'form-field-date': FormFieldDate,
            'form-field-currency': FormFieldCurrency,
            'form-field-ssn': FormFieldSsn,
            'form-field-address': FormFieldAddress,
        },
        props: {
            initialData: { type: Object, required: false },
            buttonCta: { type: String, default: i18n.t('components.applicationForm.defaultCta') },
            showLegal: { type: Boolean, default: true },
            showSoftPullLegal: { type: Boolean, default: true },
            showNoSoftPullLegal: { type: Boolean, default: false },
            showButtonIcon: { type: Boolean, default: true },
            showFirstLastNameInput: { type: Boolean, default: true },
            showEmailInput: { type: Boolean, default: true },
            showAddressInput: { type: Boolean, default: true },
            showStatedIncomeInput: { type: Boolean, default: true },
            showIdentityCheckSectionHeader: { type: Boolean, default: true },
            showSSNInput: { type: Boolean, default: true },
            lastFourOnly: { type: Boolean, default: true },
            showDOBInput: { type: Boolean, default: true },
            disableFirstLastNameInput: { type: Boolean, default: false },
            disableAddressInput: { type: Boolean, default: false },
            requireConfirmPrimaryResidence: { type: Boolean, default: false },
            mustBeCAResident: { type: Boolean, default: true },
        },
        mixins: [misc],
        data: function () {
            return {
                submitting: false,
                error: false,
                errorText: '',
                // Text Fields
                firstName: '',
                lastName: '',
                emailAddress: '',
                statedIncome: '',
                ssn: '',
                applicantDOB: '',
                // address data is {addressComponents: {addressStreet, addressCity, addressState, addressPostalCode, addressUnit, country}, inputType: {'automatic' | 'manual'}}
                addressData: null,
                isPrimaryResidenceConfirmed: false,
                primaryResidenceError: '',
            }
        },
        created: function () {
            if (this.initialData) {
                this.firstName = this.initialData.firstName
                this.lastName = this.initialData.lastName
                this.addressData = this.initialData.addressData
                this.statedIncome = this.initialData.statedIncome
                this.ssn = this.initialData.ssn
                this.applicantDOB = this.initialData.applicantDOB
                this.emailAddress = this.initialData.emailAddress
            }
        },
        methods: {
            saveFormData: function () {
                // Save form data to local storage
                let basicInfo = JSON.parse(appSessionStorage.getItem(localStorageKey.basicInfo))
                if (this.showFirstLastNameInput) {
                    basicInfo = Object.assign({}, basicInfo, { firstName: this.firstName, lastName: this.lastName })
                }
                if (this.showStatedIncomeInput) {
                    basicInfo = Object.assign({}, basicInfo, { statedIncome: this.statedIncome })
                }
                if (this.showDOBInput) {
                    basicInfo = Object.assign({}, basicInfo, { applicantDOB: this.applicantDOB })
                }
                if (this.showSSNInput) {
                    basicInfo = Object.assign({}, basicInfo, { ssn: this.ssn })
                }
                if (this.showEmailInput) {
                    basicInfo = Object.assign({}, basicInfo, { emailAddress: this.emailAddress })
                }
                if (this.showStatedIncomeInput) {
                    basicInfo = Object.assign({}, basicInfo, { statedIncome: this.statedIncome })
                }
                appSessionStorage.setItem(localStorageKey.basicInfo, JSON.stringify(basicInfo))
                return basicInfo
            },
            onAddressInputChange(value) {
                this.addressData = value
            },
            onClearAddress() {
                this.addressData = null
            },
            onError(errorText) {
                this.error = true
                this.errorText = errorText
                this.scrollToTop()
            },
            onSubmit: async function () {
                console.log('Submitting form')
                // Check to make sure it is not already been submitted
                if (this.submitting) {
                    return
                }
                this.submitting = true

                // Check if base is valid
                let isValid = await this.$refs.form.$refs.observer.validate()
                if (this.showAddressInput && this.$refs.addressField.useAutocomplete) {
                    isValid &= this.$refs.addressField.validateAutocompleteAddress()
                }
                const isPrimaryResidenceError = this.requireConfirmPrimaryResidence && !this.isPrimaryResidenceConfirmed
                this.primaryResidenceError = isPrimaryResidenceError ? 'We currently only support primary residences. Please confirm this is your primary residence or use a different address.' : ''
                if (!isValid || isPrimaryResidenceError) {
                    const el = document.querySelector('.is-invalid:first-of-type')
                    el?.scrollIntoView()
                    this.submitting = false
                    return
                }

                const applicantData = this.saveFormData()

                console.log('Emitting data from form')
                this.$emit('on-submit', applicantData)
                this.submitting = false
            },
            loadData(value) {
                logger.info(`ApplicationForm loadData`)

                if (this.showFirstLastNameInput) {
                    this.firstName = value.firstName
                    this.lastName = value.lastName
                }
                if (this.showStatedIncomeInput) {
                    this.statedIncome = value.statedIncome
                }
                if (this.showSSNInput) {
                    this.ssn = value.ssn
                }
                if (this.showDOBInput) {
                    this.applicantDOB = value.applicantDOB
                }
                if (this.showEmailInput) {
                    this.emailAddress = value.emailAddress
                }
            },
        },
    }
</script>
