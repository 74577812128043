import axios, { AxiosInstance } from 'axios'
import { logger } from '@/utils/logger'
import { runWithRetryLogic } from '@/utils/http-client'

export interface SmartySuggestion {
    street_line: string
    secondary: string
    city: string
    state: string
    zipcode: string
}

export class ValidatedAddress {
    addressStreet: string
    addressCity: string
    addressState: string
    addressPostalCode: string
    addressUnit: string
    addressValidationError: AddressValidationError

    constructor(street: string, city: string, state: string, zipcode: string, secondary: string = null, addressValidationError: AddressValidationError = null) {
        this.addressStreet = street
        this.addressUnit = secondary
        this.addressCity = city
        this.addressState = state
        this.addressPostalCode = zipcode
        this.addressValidationError = addressValidationError
    }

    public fullDescription = () => {
        const street = this.addressUnit ? `${this.addressStreet} ${this.addressUnit},` : `${this.addressStreet}`
        return `${street}, ${this.addressCity}, ${this.addressState} ${this.addressPostalCode}`
    }

    public descriptionWithNoSecondaryAddress = () => {
        return `${this.addressStreet}, ${this.addressCity}, ${this.addressState} ${this.addressPostalCode}`
    }
}

// https://www.smartystreets.com/docs/cloud/us-street-api#http-response-output
export enum InvalidSmartyStreetFootnoteCodes {
    A1 = 'A1', // Address is invalid.
    M1 = 'M1', // Primary number (e.g., house number) is missing

    N1 = 'N1', // Address is missing secondary information (apartment, suite, etc.).
    CC = 'CC', // The submitted secondary information (apartment, suite, etc.) was not recognized,

    F1 = 'F1', // Military or diplomatic address
    PB = 'PB', // PO Box street style address.
    P1 = 'P1', // PO, RR, or HC box number is missing.
    P3 = 'P3', // PO, RR, or HC box number is invalid.
    RR = 'RR', // Confirmed address with private mailbox (PMB) info.
    R7 = 'R7', // Confirmed as a valid address that doesn't currently receive US Postal Service street delivery.
    U1 = 'U1', // Address has a "unique" ZIP Code. https://www.smartystreets.com/articles/unique-zip-codes
}

const InvalidMatchCodesToErrorMessage: Map<InvalidSmartyStreetFootnoteCodes, string> = new Map([
    [InvalidSmartyStreetFootnoteCodes.A1, 'Invalid Address'],
    [InvalidSmartyStreetFootnoteCodes.M1, 'Invalid or missing house number'],
    [InvalidSmartyStreetFootnoteCodes.N1, 'Please provide apartment or unit'],
    [InvalidSmartyStreetFootnoteCodes.CC, 'Invalid apartment or unit'],
    [InvalidSmartyStreetFootnoteCodes.R7, 'Address is not receiving USPS mail currently'],
])

export class AddressValidationError extends Error {
    private smartyFootnoteCodes: InvalidSmartyStreetFootnoteCodes[]
    constructor(message: string, codes: InvalidSmartyStreetFootnoteCodes[]) {
        super(message)
        this.name = 'AddressValidationError'
        this.smartyFootnoteCodes = codes
        Object.setPrototypeOf(this, new.target.prototype)
    }

    public missingSecondaryAddress = (): boolean => {
        return this.smartyFootnoteCodes.includes(InvalidSmartyStreetFootnoteCodes.N1)
    }

    public invalidSecondaryAddress = (): boolean => {
        return this.smartyFootnoteCodes.includes(InvalidSmartyStreetFootnoteCodes.CC)
    }

    public description = (): string => {
        return `AddressValidationError, ${this.message}, codes: ${JSON.stringify(this.smartyFootnoteCodes)}`
    }
}
export class SmartyStreetClient {
    private SMARTY_STREET_AUTO_COMPLETE_URL = 'https://us-autocomplete-pro.api.smartystreets.com/lookup'
    private SMARTY_STREET_VALIDATE_URL = 'https://us-street.api.smartystreets.com/street-address'
    private httpClient: AxiosInstance

    private invalidMatchCodesArray: string[] = Object.values(InvalidSmartyStreetFootnoteCodes)

    constructor() {
        this.httpClient = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            timeout: 10000,
        })
        this.httpClient.interceptors.response.use(undefined, function smartyErrorStatusHandler(error) {
            if (error.status == 401) {
                logger.error('Smarty Street API auth error, check VUE_APP_SMARTY_STREET_API_KEY in env file against what is in Smarty Street account-> API Keys', null /* event */, error)
            } else if (error.status == 402) {
                logger.error('Smarty Street API error, payment required!', null /* event */, error)
            } else if (error.status == 413) {
                logger.error(`Smarty Street API error, request too big, request: ${JSON.stringify(error.request)}, config: ${JSON.stringify(error.config)}`, null /* event */, error)
            } else if (error.status == 429) {
                logger.error('Smarty Street API error, api call was rejected due to rate limit', null /* event */, error)
            } else if (error.status < 200 && error.status >= 300) {
                logger.error(`Smarty Street API error, status: ${error.status}`, null /* event */, error)
            }
        })
    }

    public getPredictions = async (address: string, maxResults: number = 5, preferStates: string[] = ['CA']): Promise<SmartySuggestion[]> => {
        const response = await this.httpClient.get(this.SMARTY_STREET_AUTO_COMPLETE_URL, {
            params: {
                key: process.env.VUE_APP_SMARTY_STREET_API_KEY,
                search: address,
                max_results: maxResults,
                prefer_states: preferStates.join(';'),
            },
        })
        logger.info(`input: ${address}, predictions: ${JSON.stringify(response.data['suggestions'])}`)
        return response.data['suggestions']
    }

    private _validateAddress = async (suggestion: SmartySuggestion, secondaryUnit: string = '') => {
        const params = {
            key: process.env.VUE_APP_SMARTY_STREET_API_KEY,
            street: suggestion.street_line,
            city: suggestion.city,
            state: suggestion.state,
            zipcode: suggestion.zipcode,
            match: 'invalid',
        }

        if (secondaryUnit) {
            params['secondary'] = secondaryUnit
        }
        logger.info(`validate with params: ${JSON.stringify(params)}`)
        const response = await this.httpClient.get(this.SMARTY_STREET_VALIDATE_URL, {
            params,
        })
        logger.info(`validate prediction: ${JSON.stringify(suggestion)}, response: ${JSON.stringify(response.data)}`)
        return this.parseValidationResponse(response.data)
    }

    public validateAddress = async (suggestion: SmartySuggestion, secondaryUnit: string = '') => {
        return await runWithRetryLogic(async () => await this._validateAddress(suggestion, secondaryUnit), 1)
    }

    private parseValidationResponse = (data: object[]): ValidatedAddress => {
        const smartyData = data[0]
        if (!smartyData) {
            const validationError = new AddressValidationError('Invalid Address', [InvalidSmartyStreetFootnoteCodes.A1])
            return new ValidatedAddress(null, null, null, null, null, validationError)
        }

        // see examples, https://www.smartystreets.com/products/apis/us-street-api
        // doc: // https://www.smartystreets.com/docs/cloud/us-street-api#http-response-output
        // tldr;
        // "dpv_footnotes": "AABB",
        // "AABB" ==> ['AA', 'BB']
        const matchCodes: string[] = smartyData['analysis']['dpv_footnotes'].match(/.{1,2}/g)
        // intersection of received codes and InvalidMatchCodes
        const errorCodes = matchCodes.filter((c) => this.invalidMatchCodesArray.includes(c)).map((c) => c as InvalidSmartyStreetFootnoteCodes)
        if (errorCodes.length > 0) {
            const validationError = new AddressValidationError(InvalidMatchCodesToErrorMessage.get(errorCodes[0]) || 'Cannot use this address for application', errorCodes)
            return new ValidatedAddress(null, null, null, null, null, validationError)
        }

        const addressComponents = smartyData['components']

        let addressStreet = addressComponents.street_predirection
            ? `${addressComponents.primary_number} ${addressComponents.street_predirection} ${addressComponents.street_name}`
            : `${addressComponents.primary_number} ${addressComponents.street_name}`

        if (addressComponents.street_postdirection) {
            addressStreet = `${addressStreet} ${addressComponents.street_postdirection}`
        }
        // https://app.asana.com/0/1140720807535375/1200337971243196
        // some addresses do have suffix (i.e. st, ave, dr, blvd)
        if (addressComponents.street_suffix) {
            addressStreet = `${addressStreet} ${addressComponents.street_suffix}`
        }

        if (addressComponents.extra_secondary_number) {
            // we don't know what to do with this kind of address
            // i.e. “5619 Loop 1604, Bldg E-5, Ste. 101 San Antonio TX”,
            // 101 is secondary_number, Ste is secondary_designator
            // E-5 is extra_secondary_number, Bldg is extra_secondary_designator
            // added analytics event so we can monitor this.
            window.logEvent('eventSmartyReturnedExtraSecondaryAddress', { smartyAddressComponents: addressComponents })
        }
        return new ValidatedAddress(addressStreet, addressComponents.city_name, addressComponents.state_abbreviation, addressComponents.zipcode, addressComponents.secondary_number)
    }
}
