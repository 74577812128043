<template>
    <div>
        <div class="row g-2 mb-2">
            <div class="col-sm-8">
                <form-field
                    name="addressStreet"
                    validation="required"
                    :placeholder="$t('components.formFieldAddress.placeholder.street')"
                    v-model="addressStreet"
                    @input="onChange"
                    :disabled="disabled"
                />
            </div>
            <div class="col-sm-4">
                <form-field
                    name="addressUnit"
                    :placeholder="$t('components.formFieldAddress.placeholder.unit')"
                    v-model="addressUnit"
                    @input="onChange"
                    :disabled="disabled"
                />
            </div>
        </div>

        <form-field
            class="mb-2"
            name="addressCity"
            validation="required"
            :placeholder="$t('components.formFieldAddress.placeholder.city')"
            v-model="addressCity"
            @input="onChange"
            :disabled="disabled"
        />

        <div class="row g-2 mb-2">
            <div class="col-6 col-sm-8">
                <form-field
                    v-if="disabled"
                    name="addressState"
                    :validation="`required${mustBeCAResident ? '|californiaResidencyRule' : ''}`"
                    @input="onChange"
                    v-model="addressStateName"
                    :disabled="disabled"
                />
                <form-select-autocomplete
                    v-else
                    ref="formSelectAutoComplete"
                    name="addressState"
                    data-label="name"
                    :placeholder="$t('components.formFieldAddress.placeholder.state')"
                    :data="statesNames"
                    @input="onChange"
                    :validation-rules="`required${mustBeCAResident ? '|californiaResidencyRule' : ''}`"
                    v-model="addressStateName"
                />
            </div>
            <div class="col-6 col-sm-4">
                <form-field
                    name="addressPostalCode"
                    validation="required|min:5|max:5"
                    @input="onChange"
                    :placeholder="$t('components.formFieldAddress.placeholder.postalCode')"
                    v-model="addressPostalCode"
                    :disabled="disabled"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import FormField from '@/components/base/FormField'
    import keys from 'lodash/keys'
    import invert from 'lodash/invert'
    import { us_states } from '@/utils/us-states-dictionary'
    import FormSelectAutocomplete from '@/components/base/FormSelectAutocomplete'
    import format from '@/mixins/format'
    import { misc } from '@/mixins/misc'
    export default {
        name: 'FormAddressManualInput',
        props: {
            initialValue: { type: Object, required: false },
            disabled: { type: Boolean, default: false },
            mustBeCAResident: { type: Boolean, default: true },
        },
        components: {
            'form-field': FormField,
            'form-select-autocomplete': FormSelectAutocomplete,
        },
        mixins: [format, misc],
        data() {
            return {
                addressStreet: '',
                addressUnit: '',
                addressCity: '',
                addressStateName: 'California',
                addressStateAbbr: 'CA',
                addressPostalCode: '',
                // data for dropdown
                statesAbbr: [],
                statesNames: [],
            }
        },
        created() {
            this.statesAbbr = invert(us_states)
            this.statesNames = keys(this.statesAbbr)

            if (this.initialValue?.addressComponents) {
                this.addressStreet = this.capitalize(this.initialValue.addressComponents.addressStreet)
                this.addressCity = this.capitalize(this.initialValue.addressComponents.addressCity)
                this.addressState = this.initialValue.addressComponents.addressState
                this.addressStateAbbr = this.initialValue.addressComponents.addressState
                this.addressStateName = us_states[this.initialValue.addressComponents.addressState]
                this.addressPostalCode = this.initialValue.addressComponents.addressPostalCode
                this.addressUnit = this.initialValue.addressComponents.addressUnit
            }
        },
        methods: {
            onChange() {
                const addressComponents = {
                    addressStreet: this.addressStreet,
                    addressUnit: this.addressUnit,
                    addressCity: this.addressCity,
                    addressState: this.statesAbbr[this.addressStateName],
                    addressPostalCode: this.addressPostalCode,
                    country: 'USA',
                }

                this.$emit('on-change', { addressComponents, inputType: 'manual' })
            },
            loadData(data) {
                this.addressStreet = this.capitalize(data.addressStreet)
                this.addressCity = this.capitalize(data.addressCity)
                this.addressStateAbbr = data.addressState
                this.addressStateName = us_states[this.addressStateAbbr]
                this.addressPostalCode = data.addressPostalCode
                this.$refs.formSelectAutoComplete.updateValue(this.addressStateName)
                this.onChange()
            },
            capitalize(value) {
                return value
                    .split(' ')
                    .map((component) => format.methods.capitalize(component))
                    .join(' ')
            },
        },
    }
</script>
